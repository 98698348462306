<template>
  <v-container>
    <page-header>
      {{ $t('productMaterial') }}
    </page-header>
    <v-card
      class="pa-2"
      elevation="0"
      style="position: sticky;top: 0;z-index: 1"
    >
      <v-text-field
        v-model="searchWord"
        :placeholder="$t('searchNameCode')"
        append-icon="mdi-magnify"
        hide-details
        outlined
      />
    </v-card>
    <div
      class="py-2"
      style="display: grid;grid-template-columns: 100%;grid-gap: 12px"
    >
      <v-card
        v-for="dish in filteredDishList"
        :key="dish.id"
        class="pa-4 d-flex"
        color="grey lighten-4"
        elevation="0"
        @click="configDish(dish)"
      >
        <div>
          <div class="text-body-1">
            {{ dish.code.replaceAll('.', '') }}.{{ dish.dishName }}
          </div>
          <div class="text-body-2 mt-2">
            {{ $t('rawMaterial') }}:{{ dish.resource.length }} · {{ $t('soldPrice') }}:{{ dish.price | priceDisplay }}
          </div>
        </div>
        <v-spacer/>
        <div>
          <v-btn icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
    <nice-dialog
      v-model="showDetailDialog"
    >
      <v-card
        v-if="selectedDish"
        class="pa-6"
        elevation="0"
      >
        <page-sub-header>{{ $t('productMaterialEdit') }}</page-sub-header>
        <div
          class="py-2 mt-0"
          style="display: grid;grid-template-columns: repeat(3,minmax(0,1fr))"
        >
          <div>
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t('code') }}
            </div>
            <div class="text-body-1">
              {{ selectedDish.code }}
            </div>
          </div>
          <div>
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t('name') }}
            </div>
            <div class="text-body-1">
              {{ selectedDish.dishName }}
            </div>
          </div>
          <div>
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t('price') }}
            </div>
            <div class="text-body-1">
              {{ selectedDish.price }}
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="text-subtitle-1 font-weight-bold mt-10">
            {{ $t('rawMaterialInfo') }}
          </div>
        </div>
        <div
          class="py-2 mt-0"
          style="
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 8px"
        >
          <v-card
            v-for="r in currentResourceList"
            :key="r.dishResourceConsumption.dishResourceId"
            class="pa-2 px-4 d-flex align-center text-body-1"
            color="grey lighten-4"
            elevation="0"
          >
            <div>{{ r.dishResourceWithUnitSetUnit.dishResource.name }}</div>
            <v-spacer/>
            &times;
            <div class="mx-2">
              {{ r.dishResourceConsumption.consumption }}
            </div>
            <div class="text-body-2 font-weight-bold mr-8">
              {{ unitIdToName(r.dishResourceConsumption.resourceUnitId) }}
            </div>
            <v-btn
              icon
              @click="removeResourceFromDish(r)"
            >
              <v-icon size="24">
                mdi-delete-circle
              </v-icon>
            </v-btn>
          </v-card>
          <div class="mt-4">
            <v-btn
              outlined
              style="background: white"
              @click="addAResource"
            >
              <v-icon left>
                mdi-plus-circle
              </v-icon>
              {{ $t('addOtherRawMaterial') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </nice-dialog>
    <nice-dialog
      v-model="showResourcePicker"
    >
      <v-container class="px-6">
        <page-sub-header v-if="selectedDish">
          {{ $t('addRawMaterial', { selectedDish: selectedDish.dishName }) }}
        </page-sub-header>
        <v-autocomplete
          v-model="selectedResource"
          :items="resourceList"
          :placeholder="$t('chooseOneRawMaterial')"
          item-text="name"
          outlined
          return-object
        />
        <v-text-field
          v-model="consumeCount"
          :placeholder="$t('inputOneNumber')"
          outlined
        />
        <v-autocomplete
          v-if="selectedResource"
          v-model="selectedUnit"
          :items="unitList"
          :placeholder="$t('chooseOneUnit')"
          item-text="name"
          outlined
          return-object
        />
        <div class="d-flex">
          <v-btn
            color="primary"
            elevation="0"
            @click="confirmAddResource"
          >
            <v-icon left>
              mdi-check
            </v-icon>
            {{ $t('sure') }}
          </v-btn>
          <v-btn
            class="ml-4"
            color="error"
            outlined
            @click="cancelPickResource"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            {{ $t('cancel') }}
          </v-btn>
        </div>
      </v-container>
    </nice-dialog>
  </v-container>
</template>

<script>
  import PageHeader from '@/composable/InK/typography/PageHeader'
  import Dish from '@/model/dish/Dish'
  import PageSubHeader from '@/composable/InK/typography/PageSubHeader'
  import NiceDialog from '@/composable/InK/NiceDialog'
  import Resource from '@/model/storage/Resource'
  import { deleteDishConsumption, getDishConsumptionList, setDishConsumption } from '@/model/storage'
  import { groupBy } from 'lodash'
  import Unit from '@/model/storage/Unit'
  import { keyBy } from 'lodash-es'

  export default {
    name: 'DishRelationPage',
    components: {
      PageSubHeader,
      PageHeader,
      NiceDialog,
    },
    data: function () {
      return {
        dishList: [],
        searchWord: '',
        showDetailDialog: false,
        selectedDish: null,
        dishResourceList: [],
        showResourcePicker: false,

        resourceList: [],
        relationDict: [],
        unitList: [],
        unitDict: null,

        selectedResource: null,
        selectedUnit: null,
        consumeCount: null,
      }
    },
    computed: {
      filteredDishList () {
        const searchWord = this.searchWord.toLowerCase()
        return this.dishList.filter(it => (it.code.toLowerCase() + it.dishName.toLowerCase()).includes(searchWord))
      },
      currentResourceList () {
        return this.selectedDish ? this.relationDict[this.selectedDish.id] ?? [] : []
      },
    },
    watch: {
      selectedResource (val) {
        if (val) {
          this.unitList = val.unitSetWithUnit.resourceUnits
        }
      },
      showResourcePicker (val) {
        if (!val) {
          this.$nextTick(() => {
            this.configDish(this.selectedDish)
          })
        }
      },
    },
    async mounted () {
      this.resourceList = await Resource.getList()
      await this.refreshRelationList()
      this.unitDict = keyBy(await Unit.getList(), 'id')
    },
    methods: {
      unitIdToName (id) {
        return this.unitDict[id].name
      },
      async refreshRelationList () {
        this.relationDict = groupBy(
          await getDishConsumptionList(),
          (it) => it.dishResourceConsumption.dishId,
        )
        this.dishList = (await Dish.getList()).map(it => {
          it.resource = this.relationDict[it.id] ?? []
          return it
        })
      },
      async configDish (dish) {
        this.selectedDish = dish
        this.showDetailDialog = true
      },
      addAResource () {
        this.showDetailDialog = false
        this.showResourcePicker = true
      },
      cancelEdit () {
        this.selectedDish = null
        this.showDetailDialog = false
      },
      cancelPickResource () {
        this.showResourcePicker = false
      },
      async removeResourceFromDish (r) {
        await deleteDishConsumption(
          this.selectedDish.id,
          1,
          r.dishResourceConsumption.dishResourceId,
          r.dishResourceConsumption.resourceUnitId,
        )
        await this.refreshRelationList()
      },
      async confirmAddResource () {
        const res = await setDishConsumption(
          this.selectedDish.id, 1,
          this.selectedResource.id, this.selectedUnit.id,
          this.consumeCount,
        )
        this.showDetailDialog = true
        this.showResourcePicker = false
        await this.refreshRelationList()
        console.log(res)
      },
    },
  }
</script>

<style scoped>

</style>
